 <template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top text-center">
      <h2 class="view-title">SERP Analysis Results</h2>
    </div>
    <br />
    <v-card-text>
      <template>
        <div class="text-center" v-if="loading && state.status != 'completed'">
          <br />
          <br />
          <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
          <br />
          <br />
          <span>Loading...</span>
          <br />
          <br />
          <span>{{ state.progress }}% complete.</span>
          <br />
          <br />
          <span>Please wait for results...</span>
          <br />
          <small
            ><strong>Current Step</strong>: <span>{{ state.msg }}</span></small
          >
        </div>

        <div class="content-await" v-if="state.status == 'completed'">
          <h2 class="text-center">Data pull complete</h2>
          <div class="d-flex justify-content-center align-center" style="height: 120px">
            <breeding-rhombus-spinner :animation-duration="2000" :size="70" :color="'#2ecc71'" />
          </div>
          <span>
            <v-progress-circular indeterminate color="primary"></v-progress-circular> Creating Report... Please Wait
          </span>
          <span>
            <span class="text-bold">SERP Analysis Status:</span> {{ state.serp_status }}
          </span>
        </div>

        <div class="text-center" v-if="state.status == 'error'">
          <h2 class="text-center">Error</h2>
          <div class="data-info">
            <div>
              <span class="param-title">URL:</span>
              <a :href="state.keyword_data.url" target="_blank">
                <span class="url">{{ state.keyword_data.url }}</span>
              </a>
            </div>
          </div>
          <br /><br />
          <h2 class="text-center">Message</h2>
          <div class="cont-error-meesage">{{ state.message }}</div>
        </div>

        <div class="text-center" v-if="state.status == 'failed'">
          <!-- <v-btn elevation="2" outline class="mb-8" @click="tryAgain">Try Again <v-icon >mdi-reload</v-icon></v-btn> -->
          <h2 class="text-center">Error</h2>
          <div class="form-group">
            <p class="form-control">{{ state.message }}</p>
          </div>
        </div>
      </template>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { required, urlValidator } from '@core/utils/validation'
import { BreedingRhombusSpinner } from 'epic-spinners'
//icons
import { mdiReload } from '@mdi/js'

export default {
  components: {
    BreedingRhombusSpinner,
  },
  data() {
    return {
      loading: true,
      data: {},
      interval: '',
      state: {},
    }
  },
  mounted() {
    this.getSerpData()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions({
      getReport: 'serp_analysis/getSerp',
      status: 'serp_analysis/getStatus',
      refreshReport: 'serp_analysis/refreshReport',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    async getSerpData() {
      await this.getReport(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      ).then(resp => {
        if (resp && resp.data) {
          this.data = resp.data
          this.getStatus(resp.data.report_id)
          this.askForStatus()
        }
      })
    },
    async getStatus() {
      await this.status(
        new URLSearchParams({
          report_id: this.$route.params.report_id,
        }).toString(),
      ).then(resp => {
        if (resp && resp.data) {
          this.state = resp.data
          if (resp.data.status == 'error' || resp.data.status == 'failed') {
            this.loading = false
            clearInterval(this.interval)
          }
          if (resp.data.serp_complete === 1) {
            this.loading = false
            this.goToReport()
          }
        }
      })
    },
    async askForStatus() {
      const getState = this.getStatus
      this.interval = setInterval(function () {
        getState()
      }, 5000)
    },
    async goToReport() {
      let path = '/serp-analysis/report/' + this.state.report_id
      this.goToPath(path)
    },
    async tryAgain() {
      const form_data = {
        report_id: this.$route.params.report_id,
      }
      await this.refreshReport(form_data).then(resp => {
        if (resp && resp.data) {
          this.build = {}
          this.getSerpData()
        }
      })
    },
  },
}
</script>

<style scoped>
.content-await {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.text-bold{
  font-weight: 700;
}
</style>
